<template>
    <div class="uk-width-1-1">
        <template v-if="$can('READ', 'worker-tax-deduction')">
            <!-- Header -->
            <p class="uk-text-bold">Tax Deduction Proof Details</p>
            <section v-if="$can('READ', 'worker-tax-deduction') || $can('WRITE', 'worker-tax-deduction') || $can('EDIT', 'worker-tax-deduction')">
                <!-- 1st row -->
                <div v-if="$can('EDIT', 'worker-tax-deduction')" class="uk-margin-small-bottom">
                    <button
                        v-if="uploadTemplateLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-margin-small-right"
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="uk-button uk-button-primary uk-border-rounded uk-position-relative uk-margin-small-right"
                        @click="triggerUploadTemplate"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left"
                            :src="`${images}/outline-upload.svg`"
                            alt="Upload icon"
                        >
                        <span>Upload Template</span>
                        <input
                            ref="uploadTemplate"
                            type="file"
                            style="display: none"
                            accept=".xls, .xlsx"
                            @change="uploadTemplate"
                        />
                    </button>
                    <button
                        v-if="downloadTaxBatchTemplateLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-margin-small-right"
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-position-relative myrobin-border-blue myrobin-color-blue uk-margin-small-right"
                        @click="downloadTaxBatchTemplate"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left" 
                            :src="`${images}/download-outline-blue.svg`"
                            alt="Upload icon"
                        >
                        <span>Download Template</span>
                    </button>
                    <button
                        v-if="uploadFileLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-position-relative myrobin-background-violet myrobin-color-white"
                        @click="triggerUploadTaxPDF"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left" 
                            :src="`${images}/document-percent.svg`"
                            alt="Upload PDF icon"
                        >
                        <span>Upload PDF</span>
                        <input
                            ref="uploadTaxPDF"
                            type="file"
                            style="display: none"
                            accept=".pdf"
                            multiple="true"
                            @change="uploadTaxPDF"
                        />
                    </button>
                </div>
                <!-- 2nd row -->
                <div class="uk-flex uk-flex-between gap-10px">
                    <div v-if="$can('READ', 'worker-tax-deduction')">
                        <form class="uk-width-expand" @submit.prevent="filterMitraTaxList">
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search" />
                                <input
                                    class="uk-input uk-width-expand"
                                    type="search"
                                    aria-label="Not clickable icon"
                                    placeholder="Search worker by name"
                                    v-model="formSearch.worker_name"
                                />
                            </div>
                        </form>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="uk-button uk-border-rounded uk-position-relative myrobin-background-yellow myrobin-color-white uk-width-auto uk-margin-small-right"
                            @click="showLogs"
                        >
                            <img
                                class="uk-position-center-left uk-margin-small-left"
                                :src="`${images}/timelapse-logs.svg`"
                                alt="Logs icon"
                            >
                            <span>Error Log</span>
                        </button>
                        <!-- <button
                            v-if="$can('WRITE', 'worker-tax-deduction')"
                            type="button"
                            class="uk-button uk-button-primary uk-border-rounded uk-position-relative uk-width-auto"
                            @click="showAddWorkerTaxModalBox"
                        >
                            <img
                                class="uk-position-center-left uk-margin-small-left"
                                :src="`${images}/add-user-outline.svg`"
                                alt="Add user icon"
                            >
                            <span>Add Mitra</span>
                        </button> -->
                    </div>
                </div>
            </section>
            <!-- End header -->

            <!-- Table worker list -->
            <div
                v-if="$can('READ', 'worker-tax-deduction') || $can('EDIT', 'worker-tax-deduction') || $can('DELETE', 'worker-tax-deduction')"
                class="uk-width-1-1 uk-card uk-card-default uk-margin-top"
            >
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-middle uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-left uk-text-nowrap">Fullname</th>
                                <th class="uk-text-left uk-text-nowrap">Email</th>
                                <th class="uk-text-left uk-text-nowrap">PDF File Name</th>
                                <th class="uk-text-center uk-text-nowrap uk-width-small"></th>
                                <th class="uk-text-center uk-text-nowrap uk-width-small">Action</th>
                            </tr>
                        </thead>
                        <template v-if="tableLoading">
                            <LoadingTable :colspan="7" />
                        </template>
                        <template v-else>
                            <tbody v-if="mitraTaxList.docs.length > 0">
                                <tr v-for="(tax, index) in mitraTaxList.docs" :key="index">
                                    <td class="uk-text-left uk-text-nowrap">{{ tax.fullname || '-' }}</td>
                                    <td class="uk-text-left uk-text-nowrap">{{ tax.email || '-' }}</td>
                                    <td class="uk-text-left">
                                        <template v-if="tax.files && tax.files.length > 3">
                                            <p>{{ getFileName(tax.files[0].file_path) }}</p>
                                            <p>{{ getFileName(tax.files[1].file_path) }}</p>
                                            <p>{{ getFileName(tax.files[2].file_path) }}</p>
                                            <p>{{ `and ${tax.files.length - 3} more...` }}</p>
                                        </template>
                                        <template v-if="tax.files && tax.files.length <= 3">
                                            <p v-for="(file, indexFile) in tax.files" :key="indexFile">
                                                <template>
                                                    {{ getFileName(file.file_path) }}
                                                </template>
                                            </p>
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </td>
                                    <td class="uk-text-center uk-text-nowrap uk-width-small">
                                        <button
                                            type="button"
                                            @click="previewWorkerTax({ mitraTax: tax })"
                                        >
                                            <img
                                                :src="`${images}/visibility.svg`"
                                                alt="View icon"
                                            >
                                        </button>
                                    </td>
                                    <td class="uk-text-center uk-text-nowrap uk-width-small">
                                        <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-10px">
                                            <!-- <div v-if="$can('EDIT', 'worker-tax-deduction')" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-width-expand uk-button-primary uk-border-rounded"
                                                    @click="editWorkerTax({
                                                        action: 'edit-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Edit</button>
                                            </div> -->
                                            <div v-if="$can('EDIT', 'worker-tax-deduction') && !tax.is_distributed" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-button-default uk-width-expand uk-border-rounded myrobin-color-blue myrobin-border-blue"
                                                    @click="distributeWorkerTax({
                                                        action: 'distribute-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Send to Mitra</button>
                                            </div>
                                            <div
                                                v-else-if="$can('EDIT', 'worker-tax-deduction') && tax.is_distributed"
                                                class="uk-width-expand"
                                                uk-tooltip="title: Tax proof has been sent for this Mitra; pos: bottom-left"
                                            >
                                                <button
                                                    type="button"
                                                    class="uk-button uk-button-default uk-width-expand uk-border-rounded uk-position-relative myrobin-color-yellow myrobin-border-yellow"
                                                    @click="distributeWorkerTax({
                                                        action: 'distribute-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >
                                                    <img class="uk-position-center-left uk-margin-small-left" :src="`${images}/info-green.svg`">
                                                    <span>Send to Mitra</span>
                                                </button>
                                            </div>
                                            <div v-if="$can('DELETE', 'worker-tax-deduction')" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-width-expand uk-button-danger uk-border-rounded"
                                                    @click="deleteWorkerTax({
                                                        action: 'delete-mitra-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Delete</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="5" empty-text="No entries found" />
                        </template>
                    </table>
                </div>
                <Pagination
                    :total-data="mitraTaxList.totalDocs || 0"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End table worker list -->
        </template>

        <!-- Handler when user can't access -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access -->

        <!-- Tax upload error data modal box -->
        <div id="rejected-data-modal" class="rejected-data-modal uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    type="button"
                    class="uk-modal-close-default"
                    uk-close
                    @click="hideFailedUploadModalBox"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tax Deduction Proof Error Log</h2>
                </div>
                <div class="uk-modal-body ">
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <p class="uk-text-lead uk-margin-remove">Incorrect data</p>
                        <template>
                            <button
                                v-if="downloadRejectedDataButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                                @click="downloadRejectedData"
                            >
                                <img
                                    class="uk-position-center-left uk-margin-small-left"
                                    :src="`${images}/download-outline-white.svg`"
                                    alt="Download icon"
                                />
                                <span>Download</span>
                            </button>
                        </template>
                    </div>
                    <div class="uk-overflow-auto uk-margin-small-top">
                        <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap">Name</th>
                                    <th class="uk-text-nowrap">Email</th>
                                    <th class="uk-text-nowrap">Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="rejectedData.length > 0">
                                <tr v-for="(data, index) in rejectedData" :key="index">
                                    <td class="uk-text-nowrap">{{ data.el["Fullname"] || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.el["Email*"] || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.notes || '-' }}</td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="8" empty-text="No entries found" />
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- End tax upload error modal box -->

        <!-- Uploaded tax proof PDF S3 link modal box -->
        <div id="pdf-link-modal" class="pdf-link-modal uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    type="button"
                    class="uk-modal-close-default"
                    uk-close
                    @click="hideFailedUploadModalBox"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">PDF Files Uploaded Successfully</h2>
                </div>
                <div class="uk-modal-body ">
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <p class="uk-text-lead uk-margin-remove">Uploaded Files</p>
                        <template>
                            <button
                                v-if="downloadRejectedDataButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                                @click="downloadPDFLinkList"
                            >
                                <img
                                    class="uk-position-center-left uk-margin-small-left"
                                    :src="`${images}/download-outline-white.svg`"
                                    alt="Download icon"
                                >
                                <span>Download</span>
                            </button>
                        </template>
                    </div>
                    <div class="uk-overflow-auto uk-margin-small-top">
                        <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap">File Name</th>
                                    <th class="uk-text-nowrap uk-width-expand">S3 Link</th>
                                </tr>
                            </thead>
                            <tbody v-if="pdfLinkList.length > 0">
                                <tr v-for="(pdf, index) in pdfLinkList" :key="index">
                                    <td class="uk-text-nowrap">{{ pdf.file_path ? getFileName(pdf.file_path) : '-' }}</td>
                                    <td class="uk-text-nowrap">
                                        <div class="uk-inline uk-width-expand@m">
                                            <button
                                                class="uk-form-icon uk-form-icon-flip uk-text-muted uk-text-right uk-border-rounded"
                                                style="background-color: #e3e3e3; color: #979797; width: 125px; border: 1px solid #979797;"
                                                @click="copyPDFUrl(pdf.file_url)"
                                            >
                                                <img
                                                    class="uk-position-center-left uk-margin-small-left uk-margin-small-right"
                                                    :src="`${images}/copy-outline.svg`"
                                                    alt="Copy link icon"
                                                >
                                                <span class="uk-inline uk-text-right">Copy Link</span>
                                            </button>
                                            <input
                                                id="fileUrl"
                                                type="text"
                                                ref="fileUrl"
                                                class="uk-input uk-border-rounded uk-width-expand"
                                                style="border: 1px solid #979797;"
                                                :value="pdf.file_url"
                                                disabled=true
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="8" empty-text="No entries found" />
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of uploaded tax proof PDF S3 link modal box -->

        <!-- Add or edit worker modal box -->
        <div id="add-edit-worker-tax-modal" class="add-edit-worker-tax-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    type="button"
                    class="uk-modal-close-default"
                    uk-close
                    @click="hideAddWorkerModalBox"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{ actionTypeModalBox == 'edit-worker-tax' ? 'Edit' : 'Add' }} Mitra</h2>
                </div>
                <form data-vv-scope="formAddWorker" @submit.prevent="doAddWorker">
                    <div class="uk-modal-body">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <label
                                    for="fullName"
                                    class="uk-form-label"
                                >Full Name</label>
                                <input
                                    id="fullName"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    placeholder="Mitra full name"
                                    v-model="formAddWorker.fullname"
                                    :disabled="actionTypeModalBox == 'edit-worker-tax'"
                                >
                            </div>
                            <div class="uk-width-1-1">
                                <label
                                    for="email"
                                    class="uk-form-label"
                                >Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    class="uk-input uk-border-rounded"
                                    placeholder="Template name"
                                    v-model="formAddWorker.email"
                                    :disabled="actionTypeModalBox == 'edit-worker-tax'"
                                >
                            </div>
                            <div class="uk-width-1-1">
                                <p>PDF File</p>
                                <div v-if="mitraPdfName" class="uk-flex uk-flex-between">
                                    <div class="uk-flex">
                                        <img
                                            class="uk-margin-small-right" 
                                            :src="`${images}/pdf-icon.svg`"
                                            alt="PDF icon"
                                        >
                                        <span>{{ mitraPdfName || '-' }}</span>
                                    </div>
                                    <button @click="removeUploadSinglePDF" class="uk-flex myrobin-color-red">
                                        <img
                                            class="uk-margin-small-right"
                                            :src="`${images}/trash-bin-delete.svg`"
                                            alt="Delete file icon"
                                        >
                                        <span><u>Remove</u></span>
                                    </button>
                                </div>
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded uk-position-relative myrobin-background-violet myrobin-color-white"
                                    @click="triggerUploadSingleMitraTaxPDF"
                                >
                                    <img
                                        class="uk-position-center-left uk-margin-small-left" 
                                        :src="`${images}/document-percent.svg`"
                                        alt="Upload PDF icon"
                                    >
                                    <span>Upload PDF</span>
                                </button>
                                <input
                                    ref="singleMitraTaxPdf"
                                    type="file"
                                    style="display: none"
                                    accept=".pdf"
                                    @change="checkUploadFile"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer">
                        <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="addWorkerButtonLoading" />
                            <button
                                v-if="!addWorkerButtonLoading"
                                type="button"
                                class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                @click="hideAddWorkerModalBox"
                                >Cancel</button>
                            <button
                                v-if="!addWorkerButtonLoading"
                                type="submit"
                                class="uk-button"
                                :class="[disabledButtonAddMitra ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonAddMitra"
                            >Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- End add worker modal box -->

        <!-- Upload tax batch modal box -->
        <div id="upload-loader-modal" class="upload-loader-modal uk-flex-top" esc-close="false" bg-close="false" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical uk-width-1-4@s">
                <div class="uk-modal-body uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                    <div class="loader"></div>
                    <p>Uploading...</p>
                </div>
            </div>
        </div>
        <!-- End upload tax batch modal box -->

    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";
import formatter from '@/utils/formatter';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';

export default {
    name: 'MitraTaxListYearly',
    components: {
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
    },
    props: {
        tableLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        mitraTaxList: {
            type: Object,
            required: true,
            default: () => {}
        },
        companyName: {
            type: String,
            required: true,
        },
        taxBatchName: {
            type: String,
            required: true,
        },
        taxMonthStart: {
            type: Number,
            required: true,
        },
        taxBatchYear: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            formSearch: {
                worker_name: ''
            },
            downloadTaxBatchTemplateLoading: false,
            uploadTemplateLoading: false,
            downloadRejectedDataButtonLoading: false,
            uploadFileLoading: false,
            downloadPdfLinkLoading: false,
            actionTypeModalBox: null,
            filenameExcel: null,
            rejectedData: [],
            pdfLinkList: [],
            addWorkerButtonLoading: false,
            mitraPdfName: '',
            formAddWorker: {
                email: null,
                fullname: null,
                pdf_name: null,
                pdf_file: null
            },
            selectedWorkerTax: {
                tax_id: null,
                email: null,
                fullname: null,
                pdf_file: null
            },
            browserApi: null,
        };
    },
    computed: {
        disabledButtonAddMitra() {
            if (
                this.formAddWorker.email === null ||
                this.formAddWorker.fullname === null ||
                this.mitraPdfName === null
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    beforeMount() {
        if (window.UIkit.modal('#upload-loader-modal')) window.UIkit.modal('#upload-loader-modal').$destroy(true);
        if (window.UIkit.modal('#rejected-data-modal')) window.UIkit.modal('#rejected-data-modal').$destroy(true);
        if (window.UIkit.modal('#pdf-link-modal')) window.UIkit.modal('#pdf-link-modal').$destroy(true);
        if (window.UIkit.modal('#add-edit-worker-tax-modal')) window.UIkit.modal('#add-edit-worker-tax-modal').$destroy(true);
    },
    methods: {
        ...mapActions({
            uploadNewMonthlyTaxDeductionExcel: 'tax/uploadNewMonthlyTaxDeductionExcel',
            getTaxBatchUploadErrorLogs: 'tax/getTaxBatchUploadErrorLogs',
            addMitraTaxDeduction: 'tax/addMitraTaxDeduction',
            editMitraTaxDeduction: 'tax/editMitraTaxDeduction',
            uploadPDFTaxProof: 'tax/uploadPDFTaxProof',
        }),
        filterMitraTaxList() {
            this.$emit('filter-mitra-tax-list', this.formSearch.worker_name);
        },
        triggerUploadTemplate() {
            this.$refs.uploadTemplate.click();
        },
        async downloadTaxBatchTemplate() {
            try {
                this.downloadTaxBatchTemplateLoading = true;

                let dataExcel = [];

                const companyName = this.companyName;
                const taxBatchName = this.taxBatchName;
                const month = this.monthFormatter({ index: this.taxMonthStart });
                const year = this.taxBatchYear;

                const obj = {
                    "User ID*": null,
                    "Email*": null,
                    "Fullname": null,
                    "Link PDF*": null,
                };
                dataExcel.push(obj);

                const file_name = `Bukti Potong_${companyName}_${taxBatchName}_${month}_${year}.xlsx`;
                excelDownloader(dataExcel, file_name);
                notificationSuccess(`Tax template downloaded successfully`);
            } catch (error) {
                notificationDanger(error);
                notificationDangerCustom(`Failed to download tax template`);
            } finally {
                this.downloadTaxBatchTemplateLoading = false;
            }
        },
        async uploadTemplate(event) {
            try {
                if (event && event.target && event.target.files && event.target.files[0]) {
                    await window.UIkit.modal('#upload-loader-modal').show();
                    this.uploadTemplateLoading = true;

                    const filePath = event.target.files[0];
                    const data = new FormData();
                    data.append('id', this.$route.params.id);
                    data.append('excel', filePath);

                    const response = await this.uploadNewMonthlyTaxDeductionExcel(data);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#upload-loader-modal').hide();
                        if (response.rejected_data && response.rejected_data.length > 0) {
                            this.filenameExcel = filePath?.name;
                            this.rejectedData = response.rejected_data;

                            await window.UIkit.modal('#rejected-data-modal').show();
                        }

                        if (response.message == 'success') {
                            notificationSuccess('Tax template uploaded successfully');
                        } else {
                            notificationSuccess('No tax deduction updated');
                        }
                    } else {
                        notificationDangerCustom('Failed to upload tax template');
                    }
                } else {
                    await window.UIkit.modal('#upload-loader-modal').hide();
                    this.uploadTemplateLoading = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await Promise.all([
                    window.UIkit.modal('#upload-loader-modal').hide(),
                    this.$emit('get-tax-batch-data')
                ]);
                this.uploadTemplateLoading = false;
            }
        },
        async hideFailedUploadModalBox() {
            try {
                this.filenameExcel = null;
                this.rejectedData = [];
                this.uploadTemplateLoading = false;

                await Promise.all([
                    this.$emit('get-tax-batch-data'),
                    window.UIkit.modal('#upload-loader-modal').hide(),
                    window.UIkit.modal('#rejected-data-modal').hide()
                ]);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadRejectedData() {
            try {
                this.downloadRejectedDataButtonLoading = true;

                const dataExcel = [];
                let obj = {};
                this.rejectedData.forEach((data) => {
                    obj = {
                        "User ID*": data.el["User ID*"] || null,
                        "Email*": data.el["Email*"] || null,
                        "Fullname": data.el["Fullname"] || null,
                        "Link PDF*": data.el["Link PDF*"] || null,
                        "Notes": data.notes,
                    };

                    dataExcel.push(obj);
                });

                const fileName = `(Rejected data) ${this.filenameExcel}`;
                excelDownloader(dataExcel, fileName);
                notificationSuccess('Rejected data successfully downloaded');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadRejectedDataButtonLoading = false;
            }
        },
        async showLogs() {
            try {
                const response = await this.getTaxBatchUploadErrorLogs(this.$route.params.id);
                if (
                    response &&
                    response.status === 'OK' &&
                    response.result &&
                    response.result.rejected_data &&
                    Array.isArray(response.result.rejected_data) &&
                    response.result.rejected_data.length > 0
                ) {
                    this.filenameExcel = response.result.filename;
                    this.rejectedData = response.result.rejected_data;
                    await window.UIkit.modal('#rejected-data-modal').show();
                } else {
                    notificationSuccess('Pajak Mitra Berhasil Terupload 100%');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        triggerUploadTaxPDF() {
            this.$refs.uploadTaxPDF.click();
        },
        async uploadTaxPDF(event) {
            try {
                if (event && event.target && event.target.files && event.target.files[0]) {
                    await window.UIkit.modal('#upload-loader-modal').show();
                    this.uploadFileLoading = true;

                    const data = new FormData();
                    for (let i = 0; i < event.target.files.length; i++ ){
                        data.append('files', event.target.files[i]);
                    }
                    data.append('id', this.$route.params.id);

                    const response = await this.uploadPDFTaxProof(data);
                    if (response && response.status === 'OK') {
                        this.pdfLinkList = response.result;
                        notificationSuccess('Tax proof PDF uploaded successfully');
                    } else {
                        notificationDangerCustom('Failed to upload tax template');
                    }
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal').hide();
                this.uploadFileLoading = false;

                if (this.pdfLinkList.length > 0) {
                    await window.UIkit.modal('#pdf-link-modal').show();
                }
            }
        },
        async downloadPDFLinkList() {
            try {
                this.downloadPdfLinkLoading = true;

                const dataExcel = [];
                let obj = {};
                this.pdfLinkList.forEach((data) => {
                    obj = {
                        "File Name": data.file_path ? this.getFileName(data.file_path) : '-',
                        "S3 Link": data.file_url || '-',
                    };

                    dataExcel.push(obj);
                });

                const fileName = `PDF S3 Link.xlsx`;
                excelDownloader(dataExcel, fileName);
                notificationSuccess('PDF S3 Link excel file successfully downloaded');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadPdfLinkLoading = false;
            }
        },
        triggerUploadSingleMitraTaxPDF() {
            this.$refs.singleMitraTaxPdf.click();
        },
        removeUploadSinglePDF() {
            this.mitraPdfName = null;
            this.$refs.singleMitraTaxPdf.value = null;
        },
        checkUploadFile(event) {
            if (event.target.files.length > 0 && event.target.files[0]) {
                this.mitraPdfName = event.target.files[0].name;
            }
        },
        async uploadSingleMitraTaxPDF() {
            try {
                const data = new FormData();
                data.append('id', this.$route.params.id);
                data.append('files', this.$refs.singleMitraTaxPdf.files[0]);

                const response = await this.uploadPDFTaxProof(data);
                if (response && response.status === 'OK') {
                    this.formAddWorker.pdf_file = response.result[0];
                } else {
                    this.formAddWorker.pdf_file = null;
                }
            } catch (error) {
                this.formAddWorker.pdf_file = null;
                notificationDanger(error);
            } finally {
                this.$refs.singleMitraTaxPdf.value = null;
            }
        },
        async showAddWorkerTaxModalBox() {
            try {
                this.resetFormAddWorker();
                await window.UIkit.modal('#add-edit-worker-tax-modal').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideAddWorkerModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.resetSelectedWorkerTax();
                this.resetFormAddWorker();
                await window.UIkit.modal('#add-edit-worker-tax-modal').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doAddWorker() {
            try {
                const validate = await this.$validator.validateAll('formAddWorker.*');
                if (!validate || this.$validator.errors.any('formAddWorker.*')) return;

                this.addWorkerButtonLoading = true;

                if (this.$refs.singleMitraTaxPdf.files && this.$refs.singleMitraTaxPdf.files[0]) {
                    await this.uploadSingleMitraTaxPDF();
                }
                if (this.formAddWorker.pdf_file == null) {
                    notificationDangerCustom('PDF file is empty');
                }

                const payload = {
                    batch_id: this.$route.params.id,
                    ...this.formAddWorker
                };

                if (this.actionTypeModalBox == 'edit-worker-tax') {
                    payload.tax_id = this.selectedWorkerTax.tax_id;
                    const response = await this.editMitraTaxDeduction(payload);

                    if (response && response.status === 'OK') {
                        notificationSuccess('Mitra tax successfully updated');
                    } else {
                        notificationDangerCustom('Failed to update mitra tax');
                    }
                } else {
                    const response = await this.addMitraTaxDeduction(payload);

                    if (response && response.status === 'OK') {
                        notificationSuccess('Mitra tax added successfully');
                    } else {
                        notificationDangerCustom('Failed to add mitra tax');
                    }
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.addWorkerButtonLoading = false;
                await Promise.all([
                    this.$emit('get-tax-batch-data'),
                    this.hideAddWorkerModalBox(),
                ]);
            }
        },
        async editWorkerTax({ action, mitraTax }) {
            try {
                this.resetSelectedWorkerTax();
                this.resetFormAddWorker();
                this.actionTypeModalBox = action;

                const {
                    _id,
                    email,
                    fullname,
                    file_path,
                } = mitraTax;

                this.formAddWorker = {
                    email,
                    fullname,
                    pdf_file: file_path
                };
                this.selectedWorkerTax.tax_id = _id;
                this.mitraPdfName = this.getFileName(this.formAddWorker.pdf_file.file_path);

                await window.UIkit.modal('#add-edit-worker-tax-modal').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        resetFormAddWorker() {
            this.formAddWorker = {
                email: null,
                fullname: null,
                pdf_file: null
            };
            this.mitraPdfName = '';
        },
        resetSelectedWorkerTax() {
            this.selectedWorkerTax = {
                tax_id: null,
                email: null,
                fullname: null,
                pdf_file: null
            };
        },
        getAllFileName(files) {
            let names = files.map(x => this.getFileName(x.file_path));
            return names.join('\r\n');
        },
        getFileName(long_name) {
            let name = '';
            if (long_name) {
                const fileName = long_name.split('/');

                name = fileName[fileName.length-1];
            }

            return name;
        },
        async copyPDFUrl(url) {
            await navigator.clipboard.writeText(url);
            notificationSuccess('URL copied!');
        },
        deleteWorkerTax({ action, mitraTax }) {
            this.$emit('delete-worker-tax', { action, mitraTax });
        },
        previewWorkerTax({ mitraTax }) {           
            this.$emit('preview-worker-tax', { mitraTax });
        },
        distributeWorkerTax({ action, mitraTax }) {
            this.$emit('distribute-worker-tax', { action, mitraTax });
        },
        monthFormatter({ index }) {
            return formatter.getMonthString({ index });
        },
        changeLimit(e) {
            this.$emit('change-limit', e);
        },
        changePage(e) {
            this.$emit('change-page', e);
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}

.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.myrobin-background-violet {
    background-color: #AB47BC;
}
.gap-10px {
    gap: 10px;
}
</style>
